function Ten() {
    return (
        <div>
            <div className="pl-2 pr-2">
                <div className="collapse collapse-arrow bg-base-100 rounded-lg border-solid border-2 border-gray-400">
                    <input type="radio" name="my-accordion-1" />
                    <div className="collapse-title text-xl font-bold">
                        📃 설문조사
                    </div>
                    <div className="collapse-content">
                        기말고사를 보기 전 학교 친구들과 함께 수련회를 다녀 왔었는데요. 학생들에게 수련회 만족도 조사를 해보았습니다. 여러 친구들에게 소감을 물어보았을때 대부분이 좋았다며 긍정적으로 답했습니다.
                        <br />
                        <br />
                        매우 좋았다며 만점을 준 학생들의 의견입니다.
                        <br />
                        <br />
                        학생1: 수련회 교관쌤들이 매우 친절하시고 재밌으셔서 좋았습니다.
                        학생2: 한 방에서 같이 자고 놀수 있는게 좋았고,이외 여러 시설을 체험하거나 노는 시간이 좋았습니다.
                        학생3: 수련회를 가서 친구들과 오래 함께 있으며 놀수있어서 기분이 좋았습니다
                        <br />
                        <br />
                        좋았지만 좀 아쉬웠다고 이야기한 학생들의 의견입니다.
                        <br />
                        <br />
                        학생1: 좋았지만 개인적으로 방이 친한 친구와 되지 못하여 아쉬웠습니다.
                        학생2: 폰 사용시간이 적어서 아쉬웠습니다.
                        <br />
                        <br />
                        불편했던 점을 말한 학생의 의견입니다.
                        <br />
                        <br />
                        학생1: 방음이 잘 안되는지 윗층에서 시끄러운 소리가 나서 불편했습니다.
                        <br />
                        <br />
                        대부분의 학생들은 친구들이랑 같이 재밌는 활동도 하고 협동하면서 좋았다는 친구들이 많았습니다. 또 친구들과 함께 있으며 서로에 대해 잘 알게 되었다는 의견과 선생님들이 학생들을 잘 챙겨주셔서 좋았다는 의견이 대다수였습니다.
                    </div>
                </div>
            </div>
            <div className="pl-2 pr-2 pt-2">
                <div className="collapse collapse-arrow bg-base-100 rounded-lg border-solid border-2 border-gray-400">
                    <input type="radio" name="my-accordion-1" />
                    <div className="collapse-title text-xl font-bold">
                        📡 광고
                    </div>
                    <div className="collapse-content">
                        <p align="middle">
                            <img className="rounded-lg" width={500} src="https://month9.kro.kr/10/comic.jpg" alt="comic" />
                        </p>
                    </div>
                </div>
            </div>
            <div className="pl-2 pr-2 pt-2">
                <div className="collapse collapse-arrow bg-base-100 rounded-lg border-solid border-2 border-gray-400">
                    <input type="radio" name="my-accordion-1" />
                    <div className="collapse-title text-xl font-bold">
                        ☀ 9반 여름날의 모습
                    </div>
                    <div className="collapse-content">
                        <a href="https://drive.google.com/uc?export=download&id=15JC3Fq3tstlF4jG07wGgJef5ou56Sh-3" target="_blank" rel="noreferrer">
                            <button className="btn btn-white text-lg md:text-2xl mb-2">
                                10월호 미디어 다운로드 (1GB)
                            </button>
                        </a>
                        <br />
                        <span style={{ color: "red" }}>파일의 크기가 너무 크고 많아 압축 파일로 대체합니다.</span>
                    </div>
                </div>
            </div>
            <div className="p-2">
                <div className="p-4 bg-base-100 rounded-lg border-solid border-2 border-gray-400">
                    디자인/편집:강현호&nbsp;&nbsp;광고/만화:김가연&nbsp;&nbsp;인터뷰:박소윤&nbsp;&nbsp;기획 기사:홍지유&nbsp;&nbsp;사설/논설:황도경
                </div>
            </div>
        </div>
    );
}

export default Ten;
