import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="mr-2 ml-2 p-8 rounded-lg border-solid border-2 border-gray-400">
      <p align="middle">
        <span className="text-5xl md:text-8xl font-bold">404 Page Not Found</span>
        <br />
        <br />
        <Link to="/">
          <button className="btn btn-white text-2xl md:ml-2 mt-2">홈으로 돌아가기</button>
        </Link>
      </p>
    </div>
  );
}

export default NotFound;
