import './App.css';
import { Route, Routes, Link } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import Five from './5/5';
import Ten from './10/10';
import NotFound from './NotFound';

function App() {
  return (
    <div>
      <div className="w-auto navbar m-2 rounded-lg">
        <div className="flex-1">
          <a className="font-bold text-white text-2xl" href="/">&nbsp;월간 9반</a>
        </div>
        <div className="flex-none gap-2">
          <div className="dropdown dropdown-end">
            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
              <div className="rounded-full">
                <FiMenu color="white" size="40" />
              </div>
            </div>
            <ul tabIndex={0} className="mt-3 z-[1] menu menu-sm dropdown-content bg-base-100 rounded-box w-48 border-solid border-2 border-gray-400">
              <li><Link to="/">홈</Link></li>
              <li><Link to="/5">5월호</Link></li>
              <li><Link to="/10">10월호</Link></li>
              <li><a href="https://ko335.channel.io" target="_blank" rel="noreferrer">문의하기</a></li>
            </ul>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<Ten />} />
        <Route path="/5" element={<Five />} />
        <Route path="/10" element={<Ten />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
