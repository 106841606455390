function Five() {
    return (
        <div>
            <div className="ml-2 mr-2 rounded-lg border-solid border-2 border-gray-400">
                <div className="collapse collapse-arrow bg-base-100">
                    <input type="radio" name="my-accordion-1" />
                    <div className="collapse-title text-xl font-bold">
                        🎤 인터뷰
                    </div>
                    <div className="collapse-content">
                        <span className="font-bold">
                            2024년 4월 18일
                            <br />
                            실시간 학생/선생님 인터뷰
                        </span>
                        <br />
                        <br />
                        <p>
                            여러 학생들의 첫 중간고사 전의 소감을 조사해 보았습니다.
                            <br />
                            <br />
                            <span className="font-bold">
                                &lt;김OO 학생 인터뷰&gt;
                            </span>
                            <br />
                            1. 1학년에서 2학년이 되어서 첫 시험이 다가오는데 기분이 어떤가요?
                            <br />
                            너무 힘들어요. 시험이 이제 11일 남았는데 그것 때문에 강박과 압박이 심해졌어요.
                            <br />
                            <br />
                            2. 자신만의 멘탈이나 스트레스 관리 방법이 있나요?
                            <br />
                            취미 활동처럼 좋아하는 것을 하며 시간을 보내거나 하루 루틴의 시간에 맞춰 산책을 가거나 자전거를 타요.
                            <br />
                            <br />
                            3. 곧 있는 중간고사에 대해 어떻게 생각하나요?
                            <br />
                            이런 학교 수업 방식에 좀 불만이 있어요. 각 학생들마다 잘하는 재능은 다 다른데 자신의 재능을 다 발휘하지 못하고 선생님들이 하라는것만 하는 학생들의 모습을
                            보면
                            안타까워요.
                            <br />
                            <br />
                            <span className="font-bold">
                                &lt;시험에 대한 여론 조사&gt;
                            </span>
                            <br />
                            윤OO: 시험자체는 좋지만 준비 과정이 힘들어요.
                            <br />
                            조OO: 좋지않다고 생각해요. 수면부족 정신질환이 악화되는 것 같아요.
                            <br />
                            김OO: 자신이 준비하는 것을 테스트하는것으로 나쁘지 않아요. 하지만 시험 하나 때문에 목숨을 거는 현재 상황은 별로 좋아보이지 않아요.
                            <br />
                            고OO: 나쁘지 않다고 생각해요. 준비한 것을 테스트한다는 것이 좋은 것 같아요.
                            <br />
                            이OO: 나쁘지않음. 준비과정을 스스로해서 시험을 보는것이니 좋다고 생각해요. 하지만 교육체제가 바뀌어야한다고 생각하긴 해요.
                            <br />
                            <br />
                            2024년이 되어 새로운 1학년 학생들이 입학했는데요.
                            <br />
                            1학년 학생들의 근황과 분위기 등을 1학년 담당 선생님께 직접 물어보았습니다.
                            <br />
                            <br />
                            <span className="font-bold">
                                &lt;함현경 선생님 인터뷰&gt;
                            </span>
                            <br />
                            1. 요즘 새로온 1학년 학생들의 전체적인 분위기가 어떤가요?
                            <br />
                            작년에 비해 시끄럽고 복도에 나와서 쉬는시간에 많이 활발하다. 작년에 비해 무리를 지어서 다니는 아이들이 더 많다.
                            <br />
                            <br />
                            2. 작년 1학년과 비교했을 때 올해 1학년의 수업태도는 어떤가요?
                            <br />
                            작년 1학년 학생들이 더 집중을 잘했다.
                            <br />
                            <br />
                            3. 작년 1학년을 떠나보낸 소감은 어떠신가요?
                            <br />
                            작년 1학년이 그립다. 그래도 작년 1학년이 2학년 올라가서 선생님들이 많이 걱정했는데, 현재 2학년 학생들이 잘 생활하는 것 같아 보람차다.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-2 ml-2 mr-2 rounded-lg border-solid border-2 border-gray-400">
                <div className="collapse collapse-arrow bg-base-100">
                    <input type="radio" name="my-accordion-1" />
                    <div className="collapse-title text-xl font-bold">
                        📡 광고
                    </div>
                    <div className="collapse-content">
                        <p align="middle">
                            <img className="rounded-lg" width={500} src="https://month9.kro.kr/5/comic.png" alt="comic" />
                            <span>❗ 다크모드에선 해당 이미지가 제대로 보이지 않을 수 있습니다.</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-2 ml-2 mr-2 rounded-lg border-solid border-2 border-gray-400">
                <div className="collapse collapse-arrow bg-base-100">
                    <input type="radio" name="my-accordion-1" />
                    <div className="collapse-title text-xl font-bold">
                        🌸 9반 봄날의 모습
                    </div>
                    <div className="collapse-content">
                        <span className="font-bold">
                            Happy birthday!
                        </span>
                        <br />
                        유현서 생일 3월 26일
                        <br />
                        박태산 생일 4월 3일
                        <br />
                        정지성 생일 4월 21일
                        <div className="md:join">
                            <img className="md:w-6/12 md:join-item" src="https://month9.kro.kr/5/1715592590419.jpg" alt="birthday party" />
                            <img className="md:w-6/12 md:join-item" src="https://month9.kro.kr/5/1715592590737.jpg" alt="birthday party" />
                        </div>
                        <br />
                        <br />
                        <span className="font-bold">
                            9반 갤러리
                        </span>
                        <div className="md:join">
                            <img className="md:w-6/12 md:join-item" src="https://month9.kro.kr/5/1715590773105.jpg" alt="gallery" />
                            <img className="md:w-6/12 md:join-item" src="https://month9.kro.kr/5/1715590773287.jpg" alt="gallery" />
                        </div>
                        <div className="md:join">
                            <img className="md:w-6/12 md:join-item" src="https://month9.kro.kr/5/1715590773380.jpg" alt="gallery" />
                            <img className="md:w-6/12 md:join-item" src="https://month9.kro.kr/5/1715590773463.jpg" alt="gallery" />
                        </div>
                        <div className="md:join">
                            <img className="md:w-6/12 md:join-item" src="https://month9.kro.kr/5/1715590773564.jpg" alt="gallery" />
                            <img className="md:w-6/12 md:join-item" src="https://month9.kro.kr/5/1715590776962.jpg" alt="gallery" />
                        </div>
                        <img className="md:w-6/12" src="https://month9.kro.kr/5/1715592024040.jpg" alt="gallery" />
                    </div>
                </div>
            </div>
            <div className="m-2 p-2 rounded-lg border-solid border-2 border-gray-400">
                <div className="p-2 bg-base-100">
                    디자인/편집:강현호&nbsp;&nbsp;광고/만화:김가연&nbsp;&nbsp;인터뷰:박소윤&nbsp;&nbsp;기획 기사:홍지유&nbsp;&nbsp;사설/논설:황도경
                </div>
            </div>
        </div>
    );
}

export default Five;
